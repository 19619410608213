
export const removeDataScripts = () => {
    const elementsWithDataScripts = document.querySelectorAll('[data-scripts="page-scripts"]');
    elementsWithDataScripts.forEach(element => {
        element.parentNode.removeChild(element);
    });
}

export const appendScriptToElement = (scriptString, tagElement, position, dataScripts) => {
    const tempContainer = document.createElement('html');
    tempContainer.innerHTML = scriptString;

    const scripts = tempContainer.getElementsByTagName('script');
    const scriptPromises = [];

    for (let i = 0; i < scripts.length; i++) {
        let newScriptElement = document.createElement('script');
        newScriptElement.src = scripts[i].src;
        newScriptElement.innerHTML = scripts[i].innerHTML;
        newScriptElement.async = scripts[i].async;

        if (dataScripts) {
            newScriptElement.setAttribute('data-scripts', dataScripts);
        }

        const scriptPromise = new Promise((resolve, reject) => {
            newScriptElement.onload = () => {
                console.log(`Script loaded: ${newScriptElement.src}`);

                resolve();
            };

            newScriptElement.onerror = (error) => {
                console.error(`Script error: ${error}`);
                reject();
            };

            if (position === 'top') {
                tagElement.insertBefore(newScriptElement, tagElement.firstChild);
            } else {
                tagElement.appendChild(newScriptElement);
            }
        });

        scriptPromises.push(scriptPromise);
    }

    // Ждем загрузки всех скриптов
    Promise.all(scriptPromises)
        .then(() => {
            console.log('All scripts loaded and executed');
        })
        .catch(error => {
            console.error('Script loading failed', error);
        });
}

export const pageScripts = (json) => {
    removeDataScripts(); // Удаляем старые скрипты, если они есть

    const headScriptString = json.headScript;
    const bodyTopScriptString = json.bodyTopScript;
    const bodyBottomScriptString = json.bodyBottomScript;

    if (headScriptString) {
        appendScriptToElement(headScriptString, document.head, null, 'page-scripts');
    }
    if (bodyTopScriptString) {
        appendScriptToElement(bodyTopScriptString, document.body, 'top', 'page-scripts');
    }
    if (bodyBottomScriptString) {
        appendScriptToElement(bodyBottomScriptString, document.body, null, 'page-scripts');
    }

    // Добавляем скрипт, который пришел с админки
    const customScript = json.customScript; // Пример, как может быть передан скрипт в json
    if (customScript) {
        appendScriptToElement(customScript, document.body, null, 'project-script');
    }
}


export const projectScripts = (json) => {
    const projectScripts = document.querySelectorAll('[data-scripts="project-script"]');
    if (!projectScripts.length) {
        json.map(item => {
            if (item.positionType === 0) {
                const headScriptStringAll = item.body;
                appendScriptToElement(headScriptStringAll, document.head, null, 'project-script');
            } else {
                const bodyScriptStringAll = item.body;
                appendScriptToElement(bodyScriptStringAll, document.body, null, 'project-script');
            }
        })
    }
}


export const pageMetaTag = (json) => {
    const titleTag = document.querySelector('title');
    titleTag.textContent = json?.title;

    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    descriptionMetaTag.setAttribute('content', json?.description);

    const canonicalUrlTag = document.querySelector('link[rel="canonical"]');
    canonicalUrlTag.setAttribute('href', json?.canonicalUrl);

    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    keywordsMetaTag.setAttribute('content', json?.keywords);
}
